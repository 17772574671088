(function () {
  'use strict';

  angular
    .module('neo.home.phases.inscription')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.phases.inscription', {
        url: '/inscription',
        templateUrl: 'home/phases/inscription/inscription.tpl.html',
        controller: 'InscriptionPhaseCtrl',
        controllerAs: 'vm',
        resolve: {
          phases: function (Phases) {
            return Phases.query().$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          genders: function (Genders) {
            return Genders.query().$promise;
          },
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
            /*return [{id: 1, name: "Pablito"}]*/
          }
        }
      });
  }
}());
